import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard
const Dashboard = Loadable(lazy(() => import('pages/apps/dashboard')));

// Profile
const ProfileEdit = Loadable(lazy(() => import('pages/apps/profile/edit')));

// user managment
const UserList = Loadable(lazy(() => import('pages/apps/user-managment/user/list')));
const SellerList = Loadable(lazy(() => import('pages/apps/user-managment/seller/list')));
const CustomersList = Loadable(lazy(() => import('pages/apps/user-managment/customers/list')));
const CustomerProfile = Loadable(lazy(() => import('pages/apps/user-managment/customers/create-edit')));
const CustomerCart = Loadable(lazy(() => import('pages/apps/user-managment/customers/cart')));
const SellerProfile = Loadable(lazy(() => import('pages/apps/user-managment/seller/tabs')));
const RoleList = Loadable(lazy(() => import('pages/apps/user-managment/role/list')));
const RoleCreateEdit = Loadable(lazy(() => import('pages/apps/user-managment/role/create-edit')));

// catalogue
const CategoryList = Loadable(lazy(() => import('pages/apps/catalogue/category/list')));
const CategoryCreateEdit = Loadable(lazy(() => import('pages/apps/catalogue/category/create-edit')));
const ReviewsList = Loadable(lazy(() => import('pages/apps/catalogue/product-feedback/reviews/list')));
const QuestionsList = Loadable(lazy(() => import('pages/apps/catalogue/product-feedback/questions/list')));
const ViewEditQuestion = Loadable(lazy(() => import('pages/apps/catalogue/product-feedback/questions/view-edit')));
const AttributesGroupsList = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/attribute-groups/list')));
const CreateEditAttributesGroups = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/attribute-groups/create-edit')));
const AttributesList = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/attributes/list')));
const AttributesCreateEdit = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/attributes/create-edit')));
const OptionsList = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/options/list')));
const CreateEditOptions = Loadable(lazy(() => import('pages/apps/catalogue/product-specs/options/create-edit')));
const ProductLibraryList = Loadable(lazy(() => import('pages/apps/catalogue/product-library/list')));
const CreateEditProductLibrary = Loadable(lazy(() => import('pages/apps/catalogue/product-library/steps')));
const BrandsList = Loadable(lazy(() => import('pages/apps/catalogue/brands/list')));
const CreateEditBrands = Loadable(lazy(() => import('pages/apps/catalogue/brands/create-edit')));
const ProductRequestsList = Loadable(lazy(() => import('pages/apps/catalogue/product-requests/list')));
const ProductRequestsView = Loadable(lazy(() => import('pages/apps/catalogue/product-requests/view')));
const ProductRequestsCreate = Loadable(lazy(() => import('pages/apps/catalogue/product-requests/create')));
const ProductList = Loadable(lazy(() => import('pages/apps/catalogue/products/list')));
const CreateEditProduct = Loadable(lazy(() => import('pages/apps/catalogue/products/create-edit')));
const SellerBulksUpdateList = Loadable(lazy(() => import('pages/apps/catalogue/bulks/seller-bulk-update/list')));
const SellerBulksUpdateCreate = Loadable(lazy(() => import('pages/apps/catalogue/bulks/seller-bulk-update/create')));
const SellerBulksAssign = Loadable(lazy(() => import('pages/apps/catalogue/bulks/seller-bulk-assigned/index')));

// finance
const AppAccountingList = Loadable(lazy(() => import('pages/apps/finance/accounting/list')));
const AppEarningsList = Loadable(lazy(() => import('pages/apps/finance/earnings/list')));
const AppCouponInvoicesList = Loadable(lazy(() => import('pages/apps/finance/coupon-invoices/CouponInvoiceList')));

// marketing
const AppCouponsList = Loadable(lazy(() => import('pages/apps/marketing/coupons/list')));
const AppCouponsCreate = Loadable(lazy(() => import('pages/apps/marketing/coupons/create-edit')));
const AppCouponsView = Loadable(lazy(() => import('pages/apps/marketing/coupons/view')));
const AppSeoList = Loadable(lazy(() => import('pages/apps/marketing/seo/list')));
const AppSeoCreate = Loadable(lazy(() => import('pages/apps/marketing/seo/create-edit')));
const AppAdvertisement = Loadable(lazy(() => import('pages/apps/marketing/advertisement/index')));
const NotificationsList = Loadable(lazy(() => import('pages/apps/marketing/notifications/list')));
const CreateEditNotifications = Loadable(lazy(() => import('pages/apps/marketing/notifications/create-edit')));
const AppAudience = Loadable(lazy(() => import('pages/apps/marketing/audience/list')));
const AppAudienceView = Loadable(lazy(() => import('pages/apps/marketing/audience/view')));
const AppAudienceCreateEdit = Loadable(lazy(() => import('pages/apps/marketing/audience/create-edit')));
const AppMegaMenuCreateEdit = Loadable(lazy(() => import('pages/apps/marketing/mega-menu/create-edit')));
const AppCampaigns = Loadable(lazy(() => import('pages/apps/marketing/campaigns/list')));
const ViewCampaigns = Loadable(lazy(() => import('pages/apps/marketing/campaigns/view')));
const ListBulkDiscounts = Loadable(lazy(() => import('pages/apps/marketing/bulk-discount/List')));
const CreateEditBulkDiscounts = Loadable(lazy(() => import('pages/apps/marketing/bulk-discount/create-edit')));

// content
const MedexSlider = Loadable(lazy(() => import('pages/apps/content/medex-slider/list')));
const CreateAndEditMedexSlider = Loadable(lazy(() => import('pages/apps/content/medex-slider/create-edit')));
const SellerEducationCentersList = Loadable(lazy(() => import('pages/apps/content/seller-education-center/list')));
const CreateSellerEducationCenter = Loadable(lazy(() => import('pages/apps/content/seller-education-center/create-edit')));
const Priviliges = Loadable(lazy(() => import('pages/apps/content/priviliges/list')));
const CreateAndEditPriviliges = Loadable(lazy(() => import('pages/apps/content/priviliges/create-edit')));
const Blog = Loadable(lazy(() => import('pages/apps/content/blog/list')));
const CreateAndEditBlog = Loadable(lazy(() => import('pages/apps/content/blog/create-edit')));
const ViewBlog = Loadable(lazy(() => import('pages/apps/content/blog/view')));
const FAQList = Loadable(lazy(() => import('pages/apps/content/faq/list')));
const CreateAndEditFAQ = Loadable(lazy(() => import('pages/apps/content/faq/create-edit')));

// orders
const OrdersListPage = Loadable(lazy(() => import('pages/apps/orders/list')));
const OrdersViewPage = Loadable(lazy(() => import('pages/apps/orders/view')));
const OrdersInvoicesPage = Loadable(lazy(() => import('pages/apps/orders/invoices/List')));
const ReturnReasonsList = Loadable(lazy(() => import('pages/apps/orders/return-reasons/list')));

// settings
const AppConfigList = Loadable(lazy(() => import('pages/apps/settings/config/list')));
const AppSystemNotification = Loadable(lazy(() => import('pages/apps/settings/system-notification/list')));
const EditSystemNotification = Loadable(lazy(() => import('pages/apps/settings/system-notification/edit')));

const AppConfigUpdate = Loadable(lazy(() => import('pages/apps/settings/config/update')));
const CurrencyList = Loadable(lazy(() => import('pages/apps/settings/currency/list')));
const ActivityLogList = Loadable(lazy(() => import('pages/apps/settings/activity-log/list')));
const ShippingCompanyList = Loadable(lazy(() => import('pages/apps/settings/shipping-company/list')));
const ShippingCompanyCreate = Loadable(lazy(() => import('pages/apps/settings/shipping-company/create-edit')));

// signup
const SignUp = Loadable(lazy(() => import('pages/auth/signup')));

// maintenance
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'apps',
          children: [
            {
              path: 'dashboard',
              element: <Dashboard />
            },
            {
              path: 'profile/update',
              element: <ProfileEdit />
            },
            {
              path: 'user-managment',
              children: [
                {
                  path: 'super-admins/list',
                  element: <UserList superAdmin />
                },
                {
                  path: 'admins/list',
                  element: <UserList />
                },
                {
                  path: 'seller',
                  children: [
                    {
                      path: 'list',
                      element: <SellerList />
                    },
                    {
                      path: 'create',
                      element: <SellerProfile />
                    },
                    {
                      path: 'update/:id',
                      element: <SellerProfile isEditing={true} />
                    }
                  ]
                },
                {
                  path: 'customers',
                  children: [
                    {
                      path: 'list',
                      element: <CustomersList />
                    },
                    {
                      path: 'cart/:id',
                      element: <CustomerCart />
                    },
                    {
                      path: 'update/:id',
                      element: <CustomerProfile isEditing={true} />
                    },
                    {
                      path: 'create',
                      element: <CustomerProfile />
                    }
                  ]
                },
                {
                  path: 'role',
                  children: [
                    {
                      path: 'list',
                      element: <RoleList />
                    },
                    {
                      path: 'create',
                      element: <RoleCreateEdit isEditing={false} />
                    },
                    {
                      path: 'update/:id',
                      element: <RoleCreateEdit isEditing={true} />
                    }
                  ]
                }
              ]
            },
            {
              path: 'content',
              children: [
                {
                  path: 'seller-education-center',
                  children: [
                    {
                      path: 'list',
                      element: <SellerEducationCentersList />
                    },
                    {
                      path: 'create',
                      element: <CreateSellerEducationCenter />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateSellerEducationCenter isEditing />
                    }
                  ]
                },
                {
                  path: 'medex-slider',
                  children: [
                    {
                      path: 'list',
                      element: <MedexSlider />
                    },
                    {
                      path: 'create',
                      element: <CreateAndEditMedexSlider />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateAndEditMedexSlider isEditing />
                    }
                  ]
                },
                {
                  path: 'product-content',
                  children: [
                    {
                      path: 'list',
                      element: <AppConfigList TYPE="product-content" />
                    },
                    {
                      path: 'update/:id',
                      element: <AppConfigUpdate TYPE="product-content" />
                    }
                  ]
                },
                {
                  path: 'footer',
                  children: [
                    {
                      path: 'list',
                      element: <AppConfigList TYPE="footer" />
                    },
                    {
                      path: 'update/:id',
                      element: <AppConfigUpdate TYPE="footer" />
                    }
                  ]
                },
                {
                  path: 'company-profile',
                  children: [
                    {
                      path: 'list',
                      element: <AppConfigList TYPE="company-profile" />
                    },
                    {
                      path: 'update/:id',
                      element: <AppConfigUpdate TYPE="company-profile" />
                    }
                  ]
                },
                {
                  path: 'policies',
                  children: [
                    {
                      path: 'list',
                      element: <AppConfigList TYPE="policies" />
                    },
                    {
                      path: 'update/:id',
                      element: <AppConfigUpdate TYPE="policies" />
                    }
                  ]
                },
                {
                  path: 'priviliges',
                  children: [
                    {
                      path: 'list',
                      element: <Priviliges />
                    },
                    {
                      path: 'create',
                      element: <CreateAndEditPriviliges />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateAndEditPriviliges isEditing />
                    }
                  ]
                },
                {
                  path: 'blog',
                  children: [
                    {
                      path: 'list',
                      element: <Blog />
                    },
                    {
                      path: 'create',
                      element: <CreateAndEditBlog />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateAndEditBlog isEditing />
                    },
                    {
                      path: 'view/:id',
                      element: <ViewBlog />
                    }
                  ]
                },
                {
                  path: 'seller-faq',
                  children: [
                    {
                      path: 'list',
                      element: <FAQList isSeller />
                    },
                    {
                      path: 'create',
                      element: <CreateAndEditFAQ isSeller />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateAndEditFAQ isSeller isEditing />
                    }
                  ]
                },
                {
                  path: 'customer-faq',
                  children: [
                    {
                      path: 'list',
                      element: <FAQList />
                    },
                    {
                      path: 'create',
                      element: <CreateAndEditFAQ />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateAndEditFAQ isEditing />
                    }
                  ]
                }
              ]
            },
            {
              path: 'orders',
              children: [
                {
                  path: 'all',
                  element: (
                    <OrdersListPage
                      ids="10,19,16,17,2,26,14,7,62,63,3,24,9,54,64,65,66,67,59,4,25,68,69,70,71,72,73,74,75,76,77,78,79,80,81"
                      pageName="ALL"
                    />
                  )
                },
                {
                  path: 'active',
                  element: <OrdersListPage ids="10,19,16,17,2,26,14,7,62,63,3,24,9,54,64,65,66,67,59" pageName="ACTIVE" />
                },
                {
                  path: 'new',
                  element: <OrdersListPage ids="10,19" pageName="NEW" />
                },
                {
                  path: 'in-progress',
                  element: <OrdersListPage ids="16,17,2,26,14,7,62,63,79,80" pageName="IN-PROGRESS" />
                },
                {
                  path: 'return',
                  element: <OrdersListPage pageName="RETURN" />
                },
                {
                  path: 'replace',
                  element: <OrdersListPage pageName="REPLACE" />
                },
                {
                  path: 'cancelled',
                  element: <OrdersListPage ids="3,26" pageName="CANCELED" />
                },
                {
                  path: 'completed',
                  element: <OrdersListPage ids="4,25" pageName="COMPLETED" />
                },
                {
                  path: 'aborted',
                  element: <OrdersListPage ids="4" pageName="ABORTED" />
                },
                {
                  path: 'payment-transferred',
                  element: <OrdersListPage ids="25" pageName="PAYMENT_RELEASED" />
                },
                {
                  path: 'incomplete',
                  element: <OrdersListPage ids="57" pageName="INCOMPLETED" />
                },
                {
                  path: 'view/:id',
                  element: <OrdersViewPage />
                },
                {
                  path: 'invoices',
                  children: [
                    {
                      path: 'list',
                      element: <OrdersInvoicesPage />
                    }
                  ]
                },
                { path: 'return-reasons/list', element: <ReturnReasonsList /> }
              ]
            },
            {
              path: 'catalogue',
              children: [
                {
                  path: 'product-specs',
                  children: [
                    {
                      path: 'attributes',
                      children: [
                        {
                          path: 'list',
                          element: <AttributesList />
                        },
                        {
                          path: 'create',
                          element: <AttributesCreateEdit isEditing={false} />
                        },
                        {
                          path: 'update/:id',
                          element: <AttributesCreateEdit isEditing={true} />
                        }
                      ]
                    },
                    {
                      path: 'attribute-groups',
                      children: [
                        {
                          path: 'list',
                          element: <AttributesGroupsList />
                        },
                        {
                          path: 'update/:id',
                          element: <CreateEditAttributesGroups isEditing />
                        },
                        {
                          path: 'create',
                          element: <CreateEditAttributesGroups isEditing={false} />
                        }
                      ]
                    },
                    {
                      path: 'options',
                      children: [
                        {
                          path: 'list',
                          element: <OptionsList />
                        },
                        {
                          path: 'update/:id',
                          element: <CreateEditOptions isEditing />
                        },
                        {
                          path: 'create',
                          element: <CreateEditOptions isEditing={false} />
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'category',
                  children: [
                    {
                      path: 'list',
                      element: <CategoryList />
                    },
                    {
                      path: 'update/:id',
                      element: <CategoryCreateEdit isEditing />
                    },
                    {
                      path: 'create',
                      element: <CategoryCreateEdit isEditing={false} />
                    }
                  ]
                },
                {
                  path: 'product-feedback',
                  children: [
                    {
                      path: 'reviews',
                      children: [
                        {
                          path: 'list',
                          element: <ReviewsList />
                        }
                      ]
                    },
                    {
                      path: 'questions',
                      children: [
                        {
                          path: 'list',
                          element: <QuestionsList />
                        },
                        {
                          path: 'view/:id',
                          element: <ViewEditQuestion />
                        },
                        {
                          path: 'update/:id',
                          element: <ViewEditQuestion isEditing />
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'product-library',
                  children: [
                    {
                      path: 'list',
                      element: <ProductLibraryList />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateEditProductLibrary isEditing />
                    },
                    {
                      path: 'create',
                      element: <CreateEditProductLibrary isEditing={false} />
                    }
                  ]
                },
                {
                  path: 'brands',
                  children: [
                    {
                      path: 'list',
                      element: <BrandsList />
                    },
                    {
                      path: 'create',
                      element: <CreateEditBrands isEditing={false} />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateEditBrands isEditing />
                    }
                  ]
                },
                {
                  path: 'product-requests',
                  children: [
                    {
                      path: 'list',
                      element: <ProductRequestsList />
                    },
                    {
                      path: 'view/:id',
                      element: <ProductRequestsView />
                    },
                    {
                      path: 'create',
                      element: <ProductRequestsCreate />
                    }
                  ]
                },
                {
                  path: 'products',
                  children: [
                    {
                      path: 'list',
                      element: <ProductList />
                    },
                    {
                      path: 'active/list',
                      element: <ProductList status={'3'} />
                    },
                    {
                      path: 'pending/list',
                      element: <ProductList status={'1'} />
                    },
                    {
                      path: 'closed/list',
                      element: <ProductList status={'0'} />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateEditProduct isEditing />
                    }
                  ]
                },
                {
                  path: 'bulks',
                  children: [
                    {
                      path: 'library-bulk-upload',
                      children: [
                        {
                          path: 'list',
                          element: <SellerBulksUpdateList isLibrary />
                        }
                      ]
                    },
                    {
                      path: 'seller-bulk-assigned',
                      element: <SellerBulksAssign />
                    },
                    {
                      path: 'seller-bulk-update',
                      children: [
                        {
                          path: 'list',
                          element: <SellerBulksUpdateList />
                        },
                        {
                          path: 'create',
                          element: <SellerBulksUpdateCreate />
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'finance',
              children: [
                {
                  path: 'accounting',
                  children: [
                    {
                      path: 'list',
                      element: <AppAccountingList />
                    }
                  ]
                },
                {
                  path: 'earnings',
                  children: [
                    {
                      path: 'past',
                      children: [
                        {
                          path: 'list',
                          element: <AppEarningsList TYPE="past" />
                        }
                      ]
                    },
                    {
                      path: 'future',
                      children: [
                        {
                          path: 'list',
                          element: <AppEarningsList TYPE="future" />
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'coupon-invoices',
                  children: [
                    {
                      path: 'list',
                      element: <AppCouponInvoicesList isInvoicePage={false} />
                    },
                    {
                      path: ':id',
                      element: <AppCouponInvoicesList isInvoicePage />
                    },
                    {
                      path: 'invoice/:id',
                      element: <AppCouponInvoicesList isInvoicePage={false} isInvoiceOrder />
                    }
                  ]
                }
              ]
            },
            {
              path: 'marketing',
              children: [
                {
                  path: 'advertisement',
                  element: <AppAdvertisement />
                },
                {
                  path: 'coupons',
                  children: [
                    {
                      path: 'list',
                      element: <AppCouponsList />
                    },
                    {
                      path: 'update/:id',
                      element: <AppCouponsCreate isEditing />
                    },
                    {
                      path: 'revoke/:id',
                      element: <AppCouponsCreate isEditing isRevoke />
                    },
                    {
                      path: 'view/:id',
                      element: <AppCouponsView />
                    },
                    {
                      path: 'create',
                      element: <AppCouponsCreate isEditing={false} />
                    }
                  ]
                },
                {
                  path: 'bulk-discount',
                  children: [
                    {
                      path: 'list',
                      element: <ListBulkDiscounts />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateEditBulkDiscounts />
                    },
                    {
                      path: 'revoke/:id',
                      element: <CreateEditBulkDiscounts isRevoke />
                    },
                    {
                      path: 'create',
                      element: <CreateEditBulkDiscounts />
                    },
                    {
                      path: 'buy-more-pay-less',
                      children: [
                        {
                          path: 'create',
                          element: <CreateEditBulkDiscounts isBulkDiscount={false} />
                        },
                        {
                          path: 'update/:id',
                          element: <CreateEditBulkDiscounts isBulkDiscount={false} />
                        },
                        {
                          path: 'revoke/:id',
                          element: <CreateEditBulkDiscounts isBulkDiscount={false} isRevoke />
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'seller-campaign',
                  children: [
                    {
                      path: 'list',
                      element: <AppCouponsList isSeller={true} />
                    },
                    {
                      path: 'view/:id',
                      element: <AppCouponsView isSeller={true} />
                    },
                    {
                      path: 'update/:id',
                      element: <AppCouponsCreate isEditing isSeller={true} />
                    },
                    {
                      path: 'revoke/:id',
                      element: <AppCouponsCreate isEditing isSeller={true} isRevoke />
                    },
                    {
                      path: 'create',
                      element: <AppCouponsCreate isEditing={false} isSeller={true} />
                    }
                  ]
                },
                {
                  path: 'mega-menu',
                  element: <AppMegaMenuCreateEdit />
                },
                {
                  path: 'seo',
                  children: [
                    {
                      path: 'meta',
                      children: [
                        {
                          path: 'list',
                          element: <AppSeoList isMeta />
                        },
                        {
                          path: 'update/:id',
                          element: <AppSeoCreate isMeta isEditing />
                        },
                        {
                          path: 'create',
                          element: <AppSeoCreate isMeta isEditing={false} />
                        }
                      ]
                    },
                    {
                      path: 'on-page',
                      children: [
                        {
                          path: 'list',
                          element: <AppSeoList isMeta={false} />
                        },
                        {
                          path: 'update/:id',
                          element: <AppSeoCreate isMeta={false} isEditing />
                        },
                        {
                          path: 'create',
                          element: <AppSeoCreate isMeta={false} isEditing={false} />
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'notifications',
                  children: [
                    {
                      path: 'list',
                      element: <NotificationsList />
                    },
                    {
                      path: 'update/:id',
                      element: <CreateEditNotifications isEditing />
                    },
                    {
                      path: 'resend/:id',
                      element: <CreateEditNotifications isEditing={false} isResending />
                    },
                    {
                      path: 'create',
                      element: <CreateEditNotifications isEditing={false} />
                    }
                  ]
                },
                {
                  path: 'audience',
                  children: [
                    {
                      path: 'list',
                      element: <AppAudience />
                    },
                    {
                      path: 'update/:id',
                      element: <AppAudienceCreateEdit isEditing />
                    },
                    {
                      path: 'create',
                      element: <AppAudienceCreateEdit isEditing={false} />
                    },
                    {
                      path: 'view/:id',
                      element: <AppAudienceView />
                    }
                  ]
                },
                {
                  path: 'campaigns',
                  children: [
                    {
                      path: 'list',
                      element: <AppCampaigns />
                    },
                    {
                      path: 'edit/:id',
                      element: <ViewCampaigns isAdminEditing={true} isEditing={false} isViewing={false} />
                    },
                    {
                      path: 'apply/:id',
                      element: <ViewCampaigns isApplaying isEditing={false} isViewing={false} />
                    },
                    {
                      path: 'update/:id',
                      element: <ViewCampaigns isEditing isApplaying={false} isViewing={false} />
                    },
                    {
                      path: 'view/:id',
                      element: <ViewCampaigns isViewing isEditing={false} isApplaying={false} />
                    }
                  ]
                }
              ]
            },
            {
              path: 'settings',
              children: [
                {
                  path: 'config',
                  children: [
                    {
                      path: 'list',
                      element: <AppConfigList />
                    },
                    {
                      path: 'update/:id',
                      element: <AppConfigUpdate />
                    }
                  ]
                },
                {
                  path: 'system-notifications',
                  children: [
                    {
                      path: 'list',
                      element: <AppSystemNotification />
                    },
                    {
                      path: 'update/:id',
                      element: <EditSystemNotification />
                    }
                  ]
                },
                {
                  path: 'currencies',
                  children: [
                    {
                      path: 'list',
                      element: <CurrencyList />
                    }
                  ]
                },
                {
                  path: 'shipping-companies',
                  children: [
                    {
                      path: 'list',
                      element: <ShippingCompanyList />
                    },
                    {
                      path: 'create',
                      element: <ShippingCompanyCreate />
                    },
                    {
                      path: 'update/:id',
                      element: <ShippingCompanyCreate />
                    }
                  ]
                },
                {
                  path: 'activity-log',
                  children: [
                    {
                      path: 'list',
                      element: <ActivityLogList />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: 'signup',
      element: <SignUp />
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
