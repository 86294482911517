import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ExportItemProps } from 'types/export';

interface ExportContextProps {
  exportedList: ExportItemProps[];
  updateExportedList: (item: ExportItemProps) => void;
  clearExportedList: () => Promise<void>;
  stopAllExportedItems: () => Promise<void>;
  instanceRemoveExportedItems: () => void;
  checkIfLoading: () => boolean;
  redoStoppedExportedItem: (item: ExportItemProps, new_uuid: string) => void;
}

export const ExportContext = createContext<ExportContextProps | undefined>(undefined);

export const ExportProvider = ({ children }: { children: React.ReactElement }) => {
  const [exportedList, setExportedList] = useLocalStorage<ExportItemProps[]>('exportedList', []);
  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  useEffect(() => {
    const clearListInterval = setInterval(() => {
      if (!checkIfLoading() && exportedList?.length > 0) {
        setExportedList([]);
        localStorage.removeItem('exportedList');
      }
    }, 2 * 60 * 60 * 1000); // 2 hours

    return () => {
      clearInterval(clearListInterval);
    };
  }, []);

  const updateExportedList = async (item: ExportItemProps, new_uuid?: string, isRedoing?: boolean) => {
    const existingItem = exportedList?.some((d: ExportItemProps) => item.uuid === d.uuid || item.uuid === d.stopped_uuid);
    const isStoppedItem = exportedList?.some((d: ExportItemProps) => item.uuid === d.stopped_uuid);

    if (!isCanceling) {
      if (existingItem) {
        if (!isStoppedItem) {
          setExportedList((prevList: ExportItemProps[]) => {
            const updatedList = prevList.map((d) =>
              d.uuid === item.uuid
                ? {
                    ...d,
                    ...item,
                    progress: item.progress ?? '100',
                    uuid: isRedoing && new_uuid ? new_uuid : item.uuid
                  }
                : d
            );
            return updatedList;
          });
        }
      } else {
        // if item is not in the list, add it
        setExportedList((prevList: ExportItemProps[]) => {
          const updatedList = [...prevList, { ...item, status: 'Loading', progress: '0', created_at: new Date().toISOString() }];
          return updatedList;
        });
      }
    }
  };

  const redoStoppedExportedItem = (item: ExportItemProps, new_uuid: string) => {
    setExportedList((prevList: ExportItemProps[]) => {
      const updatedList = prevList.map((d) =>
        d.uuid === item.uuid
          ? {
              ...d,
              ...item,
              uuid: new_uuid
            }
          : d
      );
      return updatedList;
    });
  };

  const clearExportedList = async () => {
    setIsCanceling(true);
    await stopAllExportedItems();
    setIsCanceling(false);
  };

  const stopAllExportedItems = async () => {
    setExportedList((prevList: ExportItemProps[]) => {
      const updatedList = prevList.map((d) => (d.status === 'Loading' ? { ...d, status: 'Stopped', stopped_uuid: d.uuid } : d));
      return updatedList;
    });
  };

  const instanceRemoveExportedItems = () => {
    localStorage.removeItem('exportedList');
    setExportedList([] as ExportItemProps[]);
  };
  const checkIfLoading = () => {
    return exportedList?.some((item: ExportItemProps) => item.status === 'Loading');
  };

  const value = {
    exportedList: exportedList || [],
    updateExportedList,
    clearExportedList,
    stopAllExportedItems,
    instanceRemoveExportedItems,
    checkIfLoading,
    redoStoppedExportedItem
  };

  return <ExportContext.Provider value={value}>{children}</ExportContext.Provider>;
};

export const useExportContext = () => {
  const context = useContext(ExportContext);
  if (context === undefined) {
    throw new Error('useExportContext must be used within a ExportProvider');
  }
  return context;
};
